<template>
    <div class="gift-content pd-10 h-calc-60">
        <div class="gift-btns">
            <el-button type="primary" @click="toAddGift">新增礼品</el-button>
        </div>
        <div class="gift-table">
            <el-table :data="tableData" height="100%">
                <el-table-column label="礼品ID" width="80" align="center" prop="gift_id"></el-table-column>
                <el-table-column label="礼品" width="400">
                   <template #default="scope">
                      <div class="gift-goods">
                          <div class="gift-img">
                              <img :src="scope.row.gift_img" :alt="scope.row.gift_name">
                          </div>
                          <div class="gift-name">
                            <span>{{scope.row.gift_name}}</span>
                          </div>
                      </div>
                   </template>
                </el-table-column>
                <el-table-column label="兑换积分值" prop="points" align="center"></el-table-column>
                <el-table-column label="状态" align="center">
                    <template #default="scope">
                        <el-switch v-model="scope.row.status" @change="openStatus(scope.row)"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="create_time" align="center" ></el-table-column>
                <el-table-column label="操作" align="center" width="200">
                    <template #default="scope">
                        <el-button :icon="Edit" type="success" plain @click="editGift(scope.row.gift_id)"></el-button>
                        <el-button :icon="Delete" type="danger" plain @click="deleteGift(scope.row.gift_id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <page-component :page="page" :limit="limit" :total="total" @change="pageChange" />
    </div>

</template>

<script setup>
import {onMounted, ref} from "vue";
import {giftList, giftDel, giftDo} from "@/utils/apis";
import {Edit, Delete} from "@element-plus/icons-vue";
import {useRoute, useRouter} from "vue-router";
import {ElMessage, ElMessageBox} from "element-plus";
import PageComponent from "@/components/common/PageComponent";

const tableData = ref([])
const page = ref(1)
const limit = ref(10)
const route = useRoute()
const router = useRouter()
const total = ref(0)

const getGifts = async () => {
    try {
        const params = {
            paging: 1,
            page: page.value,
            pageSize: limit.value
        }
        const {data, msg} = await giftList(params)
        tableData.value = data.list.map((item) => {
            item.status = item.status ? true : false
            return item;
        })
        total.value = data.total
    }catch (e) {
        ElMessage.warning(e.message)
    }
}
const openStatus = async (val) => {
    try {
        const {data, msg} = await giftDo({gift_id: val.gift_id, status: val.status})
        ElMessage({
            type: 'success',
            message: msg,
            duration: 1000,
            onClose: () => {
                getGifts()
            }
        })
    }catch (e) {
        ElMessage.warning(e.message)
    }

}
const toAddGift = () => {
    router.push({name: 'GiftAdd'})
}
const editGift = (id) => {
  router.push({name: 'GiftAdd', query: {id}})
}
const deleteGift = (id) => {
    ElMessageBox.confirm(
        '删除礼物，则无法恢复，谨慎操作，确定删除？',
        '删除礼物警告',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }
    ).then(() => {
        giftDelete(id)
    }).catch(err => {console.log(err)})
}
const giftDelete = async (id) => {
    try {
        const {data, msg} = await giftDel(id)
        ElMessage({
            type: 'success',
            message: msg,
            duration: 500,
            onClose: () => {
                getGifts()
            }
        })
    }catch (e) {
        ElMessage.warning(e.message)
    }
}
const pageChange = (val) => {
    page.value = val
    getGifts()
}
onMounted(() => {
    getGifts()
})

</script>

<style scoped lang="scss">
.gift-content {
    &, & * {
        box-sizing: border-box;
    }
    .gift-table {
        height: calc(100% - 92px);
        margin-top: 20px;
    }
    .gift-goods {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .gift-img {
            width: 100px;
            height: 100px;
            margin-right: 20px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>